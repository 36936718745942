<template>
  <div v-if="form">
    <div class="min-vh-100">
      <CRow class="no-gutters px-3 px-sm-0 mb-3">
        <b-col sm="6" class="text-center text-sm-left">
          <h1 class="mr-sm-4 header-main text-uppercase">รายละเอียดบัญชี</h1>
          <!-- <b-button v-b-modal.modal-1>Launch demo modal</b-button> -->
        </b-col>
        <b-col sm="6" class="text-center text-sm-right">
          <span class="label-text mr-3 txt-relative">สถานะบัญชี</span>
          <b-form-checkbox
            switch
            size="lg"
            class="d-inline-block mb-1"
            @change="onChangeEnableAffiliate"
            v-model="form.user.enabled"
            :value="true"
            :unchecked-value="false"
            ><span class="ml-2 main-label">{{
              form.user.enabled ? "ใช้งานปกติ" : "ระงับบัญชี"
            }}</span></b-form-checkbox
          >
        </b-col>
      </CRow>

      <b-row class="pb-sm-4 position-relative mx-3 mx-sm-0">
        <b-col cols="12" class="bg-white py-3">
          <b-row>
            <b-col sm="2" class="text-center">
              <div>
                <div
                  v-bind:style="{
                    'background-image': 'url(' + imageProfile + ')',
                  }"
                  class="preview-box border-0 mb-2 affiliate-img"
                ></div>
                <span class="f-12 one-line"
                  >เป็นสมาชิกวันที่
                  {{
                    new Date(form.user.createdTime) | moment($formatDate)
                  }}</span
                >
              </div>
            </b-col>
            <b-col sm="5" class="m-auto">
              <div class="w-100">
                <b-row class="">
                  <b-col cols="4" class="font-weight-bold one-line"
                    >ชื่อนักขายออนไลน์ :</b-col
                  >
                  <b-col class="mb-2 mb-sm-0 one-line" cols="8">
                    {{ form.user.firstname }} {{ form.user.lastname }}
                    <!-- {{ form.user.displayNameTranslation[0].name }} -->
                  </b-col>
                </b-row>

                <b-row class="">
                  <b-col cols="4" class="font-weight-bold one-line"
                    >เบอร์ติดต่อ :</b-col
                  >
                  <b-col class="mb-2 mb-sm-0 one-line" cols="8">
                    {{ form.user.telephone }}
                    <!-- {{ form.user.firstname }} {{ form.user.lastname }} -->
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col sm="5" class="m-auto">
              <b-row class="">
                <b-col cols="4" class="font-weight-bold one-line"
                  >อีเมล :</b-col
                >
                <b-col class="mb-2 mb-sm-0 one-line" cols="8">
                  {{ form.user.email }}
                  <!-- {{ form.user.firstname }} {{ form.user.lastname }} -->
                </b-col>
              </b-row>

              <b-row class="">
                <b-col cols="4" class="font-weight-bold one-line"
                  >วันเกิด :</b-col
                >
                <b-col class="mb-2 mb-sm-0 one-line" cols="8">
                  {{ form.user.birthday | moment($formatDate) }}
                  <!-- {{ form.user.firstname }} {{ form.user.lastname }} -->
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mt-3 no-gutters">
        <b-col class="bg-white p-3">
          <h1
            class="font-weight-bold header-main text-uppercase f-20 d-inline-block mb-3"
          >
            ข้อมูลสำหรับรับเงิน
          </h1>
          <b-row>
            <b-col md="6">
              <InputSelect
                class=""
                classLabelName="col-lg-4 my-auto"
                classInputName="col-lg-6"
                title="ธนาคาร"
                name="bank"
                valueField="id"
                textField="name"
                v-bind:options="bankLists"
                v-model="form.bankAccount.bankId"
                @onDataChange="handleChangeBankName"
                isRequired
                :v="$v.form.bankAccount.bankId"
              />
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="เลขที่บัญชี"
                placeholder="เลขที่บัญชี"
                type="text"
                name="gold"
                isRequired
                v-model="form.bankAccount.accountNo"
                :v="$v.form.bankAccount.accountNo"
              />
            </b-col>
            <b-col md="6">
              <InputText
                textFloat="ชื่อบัญชี"
                placeholder="ชื่อบัญชี"
                type="text"
                name="platinum"
                isRequired
                v-model="form.bankAccount.accountName"
                :v="$v.form.bankAccount.accountName"
              />
            </b-col>
          </b-row>

          <h1
            class="font-weight-bold header-main text-uppercase f-20 d-inline-block mb-3"
          >
            เอกสารยืนยันตัวตน
          </h1>

          <b-row>
            <b-col md="6">
              <UploadFile
                textFloat="บัตรประชาชน"
                placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                format="image"
                name="thumbnail"
                :fileName="form.bankAccount.idCardDocument || ''"
                text="*กรุณาอัพโหลดไฟล์สกุล .png หรือ .jpg ขนาด 1:1 ไม่เกิน 10 MB"
                isRequired
                v-on:onFileChange="onImageChange"
                v-on:delete="deleteImage"
                v-model="form.bankAccount.idCardDocument"
                :v="$v.form.bankAccount.idCardDocument"
              />
              <PreviewFile
                v-bind:urlPath="form.bankAccount.idCardDocument || ''"
              />
            </b-col>
            <b-col md="6">
              <UploadFile
                textFloat="ภาพคู่บุคคลกับเอกสารบัตรประชาชน"
                placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                format="image"
                name="thumbnail"
                :fileName="form.bankAccount.idCardWithFaceDocument || ''"
                text="*กรุณาอัพโหลดไฟล์สกุล .png หรือ .jpg ขนาด 1:1 ไม่เกิน 10 MB"
                isRequired
                v-on:onFileChange="onImageWithFaceChange"
                v-on:delete="deleteImageWithFace"
                v-model="form.bankAccount.idCardWithFaceDocument"
                :v="$v.form.bankAccount.idCardWithFaceDocument"
              />
              <PreviewFile
                v-bind:urlPath="form.bankAccount.idCardWithFaceDocument || ''"
              />
            </b-col>
            <b-col md="6" class="mt-3">
              <UploadFile
                textFloat="หน้าบัญชีธนาคาร"
                placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                format="image"
                name="thumbnail"
                :fileName="form.bankAccount.bookBankDocument || ''"
                text="*กรุณาอัพโหลดไฟล์สกุล .png หรือ .jpg ขนาด 1:1 ไม่เกิน 10 MB"
                isRequired
                v-on:onFileChange="onBankImageChange"
                v-on:delete="deleteBankImage"
                v-model="form.bankAccount.bookBankDocument"
                :v="$v.form.bankAccount.bookBankDocument"
              />
              <PreviewFile :urlPath="form.bankAccount.bookBankDocument || ''" />
            </b-col>
          </b-row>

          <b-row class="mt-5">
            <b-col md="6">
              <b-button
                :href="'/affiliate/details/' + id"
                :disabled="isDisable"
                class="btn-details-set btn-cancel"
                >ย้อนกลับ</b-button
              >
            </b-col>
            <b-col md="6" class="text-sm-right">
              <button
                type="button"
                @click="$refs.isModalAlertConfirm.show()"
                :disabled="form.bankAccount.bankApproveStatusId != 2"
                class="btn btn-danger btn-details-set ml-md-2 text-uppercase rounded-0"
              >
                ปฏิเสธบัญชี
              </button>
              <button
                type="button"
                @click="checkForm(0)"
                :disabled="isDisable"
                class="btn btn-main btn-details-set ml-md-2 text-uppercase"
              >
                บันทึก/ยืนยันบัญชี
              </button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlertConfirm
      msg="ยืนยันการปฏิเสธบัญชี ?"
      :text="
        `คุณต้องการปฏิเสธบัญชี ${form.user.firstname || ''} ${form.user
          .lastname || ''} ใช่หรือไม่ ?`
      "
      colorBtnConfirm="danger"
      btnCancel="ย้อนกลับ"
      btnConfirm="ยืนยัน"
      ref="isModalAlertConfirm"
      @confirm="reject"
    />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import UploadFile from "@/components/inputs/UploadFile";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { required } from "vuelidate/lib/validators";
import PreviewFile from "@/components/inputs/PreviewFile";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";

export default {
  name: "AffiliateDetails",
  components: {
    InputText,
    InputSelect,
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
    PreviewFile,
    ModalAlertConfirm,
  },
  data() {
    return {
      id: this.$route.params.id,
      form: null,
      bankLists: [],
      modalMessage: "",
      showPreviewBank: "",
      fileNameBank: "",
      showPreviewIdCard: "",
      showPreviewIdCardWithFace: "",
      fileNameIdCard: "",
      fileNameIdCardWithFace: "",
      isLoadingImageBank: false,
      isLoadingImage: false,
      isDisable: false,
    };
  },
  created: async function() {
    await this.getData();
    await this.getBankAccounts();
    this.$isLoading = true;
  },
  validations: {
    form: {
      bankAccount: {
        accountName: { required },
        accountNo: { required },
        bankId: { required },
        idCardDocument: { required },
        bookBankDocument: { required },
        idCardWithFaceDocument: { required },
      },
    },
  },
  computed: {
    imageProfile: function() {
      return (
        this.form.user.imageUrl ||
        "https://uat-img.getfin.me/userprofile/214802124820876.png"
      );
    },
  },
  methods: {
    getData: async function() {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Affiliate/Profile/` + this.id,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.form = resData.detail.result;
        this.showPreviewBank = this.form.bankAccount.bookBankDocument;
        this.showPreviewIdCard = this.form.bankAccount.idCardDocument;
        this.showPreviewIdCardWithFace = this.form.bankAccount.idCardWithFaceDocument;
      }
    },
    getBankAccounts: async function() {
      let banklists = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/bank/list`,
        null,
        this.$headers,
        null
      );
      if (banklists.result == 1) {
        let list = [{ id: null, name: `กรุณาเลือกธนาคาร` }];
        let datalist = banklists.detail.dataList;
        this.bankLists = list.concat(datalist);

        if (this.form.bankAccount.bankId == 0) {
          this.form.bankAccount.bankId = null;
        }
      }
    },
    saveImagetoDb: async function(img) {
      var imgData = {
        base64: img,
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    handleChangeBankName: async function(value) {
      this.form.bankAccount.bankId = value;
      let name = this.bankLists.find((item) => item.id === value).name;
      this.form.bankAccount.bankName = name;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.mobileImages = reader.result;
        var images = reader.result; //await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;

        this.showPreviewIdCard = images;
        this.form.bankAccount.idCardDocument = images;
        this.form.bankAccount.idCardDocumentFile = images;
        this.fileNameIdCard = images;
      };
    },
    onImageWithFaceChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.mobileImages = reader.result;
        var images = reader.result; //await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;

        this.showPreviewIdCardFace = images;
        this.form.bankAccount.idCardWithFaceDocument = images;
        this.form.bankAccount.idCardWithFaceDocumentFile = images;
        this.fileNameIdCardWithFace = images;
      };
    },
    onBankImageChange(img) {
      this.isLoadingImageBank = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        //this.mobileImages = reader.result;
        var images = reader.result; //await this.saveImagetoDb(reader.result);
        this.isLoadingImageBank = false;
        this.isDisable = false;

        this.showPreviewBank = images;
        this.form.bankAccount.bookBankDocument = images;
        this.form.bankAccount.bookBankDocumentFile = images;
        this.fileNameBank = images;
      };
    },
    deleteImage() {
      this.form.bankAccount.idCardDocument = null;
      this.form.bankAccount.idCardDocumentFile = null;
      //this.form.bankAccount = null;
      this.fileNameIdCard = "";
      this.showPreviewIdCard = null;
    },
    deleteImageWithFace() {
      this.form.bankAccount.idCardWithFaceDocument = null;
      this.form.bankAccount.idCardWithFaceDocumentFile = null;
      //this.form.bankAccount = null;
      this.fileNameIdCardWithFace = "";
      this.showPreviewIdCardFace = null;
    },
    deleteBankImage() {
      this.form.bankAccount.bookBankDocument = null;
      this.form.bankAccount.bookBankDocumentFile = null;
      //this.form.bankAccount = null;
      this.fileNameBank = "";
      this.showPreviewBank = null;
    },
    checkForm: async function(flag) {
      this.$v.form.$touch();
      if (this.$v.$error) {
        return;
      }

      this.modalAlertShow = false;
      this.submit();
    },
    reject: async function() {
      await this.$refs.isModalAlertConfirm.hide();
      this.$refs.modalLoading.show();

      this.isDisable = true;
      let data = await this.$callApi(
        "patch",
        `${this.$baseUrl}/api/Affiliate/Bankaccount/Reject/${this.form.bankAccount.id}`,
        null,
        this.$headers,
        null
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;
      this.isDisable = false;
      if (data.result == 1) {
        this.getData();
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    submit: async function() {
      this.$refs.modalLoading.show();

      this.isDisable = true;

      let dataRequest = {
        userId: this.id,
        Enabled: true,
        bankAccount: {
          id: this.form.bankAccount.id,
          bankId: this.form.bankAccount.bankId,
          accountName: this.form.bankAccount.accountName,
          accountNo: this.form.bankAccount.accountNo,
          IDCardDocument: this.form.bankAccount.idCardDocument,
          IDCardDocumentFile: this.form.bankAccount.idCardDocumentFile,
          BookBankDocument: this.form.bankAccount.bookBankDocument,
          BookBankDocumentFile: this.form.bankAccount.bookBankDocumentFile,
          idCardWithFaceDocument: this.form.bankAccount.idCardWithFaceDocument,
          idCardWithFaceDocumentFile: this.form.bankAccount
            .idCardWithFaceDocumentFile,
        },
      };

      let data = await this.$callApi(
        "patch",
        `${this.$baseUrl}/api/Affiliate/Bankaccount/Update`,
        null,
        this.$headers,
        dataRequest
      );

      this.$refs.modalLoading.hide();
      this.modalMessage = data.message;
      this.isDisable = false;
      if (data.result == 1) {
        this.getData();
        this.$refs.modalAlert.show();
        setTimeout(() => {
          this.$refs.modalAlert.hide();
        }, 3000);
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    onChangeEnableAffiliate: async function(value) {
      let body = {
        Enabled: value,
        UserId: this.id,
      };

      let resData = await this.$callApi(
        "patch",
        `${this.$baseUrl}/api/Affiliate/Profile/Enbled`,
        null,
        this.$headers,
        body
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        //his.getData();
      } else {
        this.$refs.modalAlertError.show();
      }
    },
  },
};
</script>

<style scoped>
.affiliate-img {
  width: 50%;
  padding-bottom: 50%;
  border-radius: 50%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin: auto;
}

.affiliate-preview {
  width: 50%;
  padding-bottom: 50%;
}

@media (max-width: 992px) {
  .affiliate-preview {
    width: 100%;
    padding-bottom: 100%;
  }
}
</style>
