<template>
  <div>
    <b-row v-if="urlPath" class="mt-1 mb-3 mb-md-0">
      <b-col cols="10" md="6" class="pr-0">
        <b-aspect :aspect="aspect">
          <div
            v-if="['jpg', 'jpeg', 'png'].indexOf(fileTypeDisplay) >= 0"
            :class="['preview-file']"
            v-bind:style="{
              'background-image': 'url(' + urlPath + ')',
            }"
          ></div>
          <embed
            v-else-if="['pdf'].indexOf(fileTypeDisplay) >= 0"
            :src="urlPath"
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
            allowfullscreen
          />
        </b-aspect>
      </b-col>
      <b-col cols="2" class="px-0">
        <font-awesome-icon
          @click="$refs.modalFile.show()"
          icon="search-plus"
          color="#6c757d"
          class="ml-2 pointer"
          size="lg"
        />
      </b-col>
    </b-row>
    <b-modal
      id="modalFile"
      ref="modalFile"
      hide-header
      hide-footer
      centered
      body-class="p-4"
      size="xl"
    >
      <div class="modal-header border-0 px-0 pt-0">
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('modalFile')"
        >
          ×
        </button>
      </div>
      <div>
        <b-container class="p-0">
          <b-row>
            <b-col>
              <div class="w-100 h-100">
                <b-aspect :aspect="aspect">
                  <div
                    v-if="['jpg', 'jpeg', 'png'].indexOf(fileTypeDisplay) >= 0"
                    :class="['preview-file']"
                    v-bind:style="{
                      'background-image': 'url(' + urlPath + ')',
                    }"
                  ></div>
                  <embed
                    v-else-if="['pdf'].indexOf(fileTypeDisplay) >= 0"
                    :src="urlPath"
                    type="application/pdf"
                    frameBorder="0"
                    scrolling="auto"
                    height="100%"
                    width="100%"
                    allowfullscreen
                  />
                </b-aspect>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    urlPath: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      aspect: "1:1.4142"
    };
  },
  computed: {
    fileTypeDisplay: function () {
      if (this.urlPath) {
        if (this.urlPath.includes("http")) {
          return this.urlPath.split(".").pop();
        }else if(this.urlPath.indexOf("base64")){
          var type =  this.urlPath.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
          return type.split("/").pop();
        }
      } else {
        return "";
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
.preview-file {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: cover; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
  background-size: contain !important;
}
</style>
